
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
$my-typography: mat.m2-define-typography-config();
@include mat.typography-hierarchy($my-typography);
@include mat.core();

@import 'vis-timeline/dist/vis-timeline-graph2d.min.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$myapp-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$myapp-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$myapp-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$myapp-theme: mat.m2-define-light-theme((
  color: (
    primary: $myapp-primary,
    accent: $myapp-accent,
    warn: $myapp-warn,
  )
));


$i95-theme-color: #1a111694;
$i95-theme-color-font: #fff;
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($myapp-theme);

/* You can add global styles to this file, and also import other style files */
html, body{
    height: 100%;
}
.op-1{
    opacity: 1 !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* width */


.pointer{
	cursor: pointer;
}
.login_inside .mat-form-field-outline{
    background: #031a24bf;
    border-radius: 6px;
}
.login_inside .mat-form-field-label {
	color: #fff;
}
.main-drawer{
/*	height: calc(100% - 50px);
	top: 50px;*/
	height: 100%;
	/*position: absolute;*/
	width: 100%;
}
.mat-drawer{
	min-width: 320px;
}
.mat-drawer-inner-container {
    /*background: rgb(104,137,173);*/
    /*background: linear-gradient(250deg, rgba(104,137,173,1) 31%, rgba(90,136,183,1) 68%);*/
}
.mat-drawer-container {
 	background: none !important;
}
.mat-drawer{
    background: none !important;
    /*opacity: 50%;*/
}
.opacity-1{
	opacity: 1 !important;
}

.module_app_container{
	width: 100%;
	height: 100%;
	position: relative;

}
.module_base_bg{
	background: #fff;
	position: absolute;
	opacity: 75%;
	height: 100%;
	width: 100%;
}
.module_content{
	position: absolute;
	height: calc( 100% - 50px);
	/*width: calc( 100% - 40px);*/
	width: 100%;
	margin: 50px 0px 0px 0px;
	/*margin-top: 50px;*/
}
.module_title{
	font-size: 20px;
}
.module_title img{
	    height: 30px;
    margin: 0px 10px;
}


.header-filter{
	background-color: #ffffff8f;
    width: 100%;
    height: auto;
    min-height: 200px;
    max-height: 500px;
    
}
.header-title{
	height: 38px;
    padding: 10px;
    font-size: 16px;
    padding-top: 15px;
    color: #385a7d;
    font-weight: 500;
    width: auto;
    float: left;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 5px !important;
    font-size: 13px;
    font-family: 'Inter';
    // position: relative;
}
.p-datatable .p-datatable-tbody .td-content-body{
    position: relative;
    padding: 0px !important;
  .td-content {
    padding: 5px;
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
  }
}

td.p-frozen-column{
  z-index: 1;
}
.p-datatable-scrollable .p-datatable-thead{
    z-index: 2 !important;
    .p-frozen-column{
      z-index: 2 !important;
    }
}


.p-datatable .p-datatable-thead > tr > th {
    padding: 10px 5px !important;
    font-size: 14px;
}
.p-datatable .p-datatable-header {
    background: #ffffff7a !important;
}
.i-ctextmenu{

    // position: relative;
    // top: -50px;
}

.mat-collapse .i-ctextmenu{
    left: -70px;
}





.p-sidebar{
    color: $i95-theme-color-font !important;
    // border-right: 2px solid #607d8b !important;
    // background: none !important;
    background: $i95-theme-color !important;
}
.all-apps-container .p-dialog-header{
    color: $i95-theme-color-font !important;
    background: $i95-theme-color !important;
}
.all-apps-container .p-dialog-content{
    color: $i95-theme-color-font !important;
    background: $i95-theme-color !important;
}

// .sidebar-container-bg{
//     background: #245a9d!important;
//     opacity: 50%;
//     position: absolute;
//     height:100%;
//     width: 100%;
// }
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {   
    color: #fff !important;
}



.table-chckbox{
    width: 30px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #fff;
}

/* Track */
::-webkit-scrollbar-track {
    border: solid 1px #e9ecef;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #9E9E9E;
    border-radius: 4px;
    border: solid 1px #f2f2f2;
    box-shadow: inset 0 0 5px grey;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7b7b7b; 
}


#confirmdialog .p-dialog-header{
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

#confirmdialog .p-dialog-footer{
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}


.p-tieredmenu{
    width: auto !important;
}

// standard user item content
.standard-user-item{
  height: 35px;
  width: 100%;
  float: left;
  margin: 5px 0px;
  display: table;
}

.p-multiselect-item {
    height: auto !important;
}

.user-default-dropdown{
  .p-dropdown-items .p-dropdown-item {
    padding: 1px 10px !important;
  }
  .cdk-virtual-scroll-content-wrapper{
    width: 100%;
  }

}

.standard-user-item .standard-name{
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  white-space: break-spaces;
  line-height: 15px;
  padding-left: 5px;
  width:100%;
}

// standard user profile photo
.standard-user-dp{
    height: 35px;
    width: 35px;
    border: solid 2px #2196f3;
    border-radius: 20px;
    float: left;
    margin-right: 5px;
}
.standard-user-dp img{
    height: 100%;
    width: 100%;
    border-radius:20px;
}

.standard-user-dp .name{
    float: left;
}

// #settings_menu .p-tieredmenu{
//     // margin-top: -45px;
// }
.no-resize{
        resize: none;
}

.light-animation{
    
.light {
    position: absolute;
    width: 0px;
    opacity: .75;
    background-color: white;
    box-shadow: #e9f1f1 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
}

.x1{
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
   -webkit-transform: scale(1.0);
   -moz-transform: scale(1.0);
   -o-transform: scale(1.0);
  transform: scale(1.0);
}

.x2{
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3{
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.x4{
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5{
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6{
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.x7{
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8{
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9{
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}


@-webkit-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-moz-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-o-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}

@-webkit-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-moz-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-o-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-webkit-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-moz-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-o-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-webkit-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@-moz-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@-o-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

}

.loader{
    .lds-loader {
        display: inline-block;
        position: absolute;
        width: 72px;
        height: 80px;
        top: calc(50% - 40px);
        left: calc(50% - 36px);
      }
    .lds-loader span{
        text-align: center;
        top: 72px;
        position: relative;
        left: 7px;
        color: gray;
      }
      .lds-loader div {
        position: absolute;
        border: 4px solid rgb(0, 0, 0);
        opacity: 1;
        border-radius: 50%;
        animation: lds-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-loader div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-loader {
        0% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 72px;
          height: 72px;
          opacity: 0;
        }
      }

}


.btn .badge-danger{
    background-color: var(--bs-red);
    
}



.disable-highlight {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }

  .new-chat-notif{
    // background-color:green;
    .p-toast-message{
      background: #fff !important;
    }
  }



  // global loader css
  .loader_body{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #0000007a;
    .loader-inner-body{
      margin: auto;
      text-align: center;
      color: #fff;
      position: relative;
      font-size: 2rem;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      i{
        font-size: 2rem;
      }
    }
  }

  .table-img-container{
    img{
        height: 50px;
        width: 50px;
        margin: 0px 1px;
        cursor: pointer;
    }
}

  .div_center{
    position:relative;
    left: -50%;
    float:right;
    width:auto;
  }
  .div_center_inner{
    float:left;
    left:50%;
    position: relative;
  }

  .moveable-origin{
    display: none;
  }
  .ng-draggable {
    cursor: grab;
  }
  
  .ng-dragging {
    cursor: grabbing;
  }
  .vis-item .vis-item-content {
    padding: 5px;
    font-size: 11px;
  }
  .is_mobile #usermodal .p-tabmenu-nav {
    width: 0%;
}

.success-msg{
  color: #155724;
}

app-login{
  .p-inline-message-icon{
    display:none;
  }
}


.module-container{
  height: fit-content !important;
  min-height:calc( 100% - 10px);
}

.text-bold{
  font-weight: bold;
}

.p-calendar{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.sail_calendar{
  .p-highlight{
    background-color: #bbe1ff !important;
  }
}

.ln-warning{
  background-color: var(--yellow-100) !important;
}
.ln-info{
  background-color: var(--cyan-100) !important;
}
.ln-success{
  background-color: var(--green-100) !important;
}
.ln-danger{
  background-color: var(--pink-100) !important;
}

i95-tables pre{
  font-family: unset !important;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 60px;
    margin-bottom: 0;
}

.dm-badge{
  padding:5px 10px;
  border-radius: 10px;
}

.truck-bg{
  background-color: #1264b5;
  color:#fff;
 
}

.barge-bg{
  background-color: #e3b220 !important;
  color:#000000 !important;
 
}

.lng-bg{
  background-color: #349e4b;
  color:#000000;
 
}

.expipe-bg{
  background-color: #000000;
  color:#fff;
 
}

#summaryTab .p-tabview .p-tabview-panels{
  padding:0 !important;
  
}

#summaryTab .p-tabview .p-tabview-nav li .p-tabview-nav-link{
  font-size:12px !important;
}

.w-summary-fuel-product{
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.w-summary-label{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.w-summary-group{
  width: 100px;
  min-width: 100px;
}

.summary-pad{
  padding:5px !important;
}

.isDisabled{
  // background-color: #d8d8d8d7;
  cursor: no-drop !important;
  opacity: 0.6;

  *{
    cursor: no-drop !important;
  }
}

.bg-grayed-out {
    .btn{
      color:#616161 !important;
    }
    .btn.active{
      background-color: #616161 !important;
      color:#fff !important;
    }
  
}

.text-bold{
  font-weight:900 !important;
}

.text-wrap{
  text-wrap: wrap !important;
}
.p-dropdown-item.p-ripple {
  white-space: normal;
}

.loader-cont{
  width: 100vw;
  height: 100vh;
  background-color: #dedede;
  opacity: 0.6;
  z-index: 99999;
  position: absolute;
  top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left:-10px;
  overflow: hidden;
  
  p{
      z-index:999999;
      font-weight: bold;
      color:#000
  }
}

.ttcapitalize.p-tooltip .p-tooltip-text {
  text-transform: capitalize;
}

.p-multiselect-header:has(> div.drpdwn-multselect-header) {
  .p-checkbox{
    display: none;
  }
}
.bold{
  font-weight: 600 Im !important;
}

.badge_container{
  float: left;
  position: relative;
  .badge{
    position: absolute;
    background: red;
    right: -4px;
    top: -6px;
    border-radius: 10px;
    font-size: 10px;
  }
}

.d-shadow{
  box-shadow:0px 1px 5px -3px
}
.border-rounded-12{
  border-radius: 12px;
}

.b-bottom{
  // border-bottom: solid 5px #0398c5;
  border-bottom: solid 3px #0398c5;
}
.h-fit-content{
  height: fit-content !important;
}