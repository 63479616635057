// css variables
$width-dialog-left: 350px;
$color-theme-dialog: #524675;



// whole body css
.i95-body{
    float: left;
    height:100%;
    width:100%;
}

.short-width-input{
    input{
        width: 50px;
    }   
}




// custom theme
.p-dialog-header {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    padding: 10px 20px !important;
}.p-dialog-footer {
    padding: 10px 20px !important;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-content,
.p-dialog .p-dialog-footer,
.p-dialog .p-tabmenu,
.p-dialog .p-tabmenu .p-tabmenu-nav,
.p-dialog .p-tabmenu .p-menuitem-link,
.p-dialog .p-dialog-header {
    // background: #ececf9 !important;
}
.note{
    font-size: 13px;
    float: left;
    width: 100%;
    color: #737a82;
    padding: 0px 5px;
    margin-bottom: 10px;
}

.p-inputtext, 
.p-dropdown, 
.p-multiselect,
.p-treeselect  {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    color: #44486D;
    background: rgba(68, 72, 109, 0.07);
    padding: 0.429rem 0.571rem;
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    
    border: solid 1px #dedede;
}

.p-dropdown .p-dropdown-label, .p-multiselect .p-multiselect-label, .p-treeselect .p-treeselect-label{
    padding: 0px !important;
}

.p-dropdown .p-dropdown-label div{
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.table-chckbox{
    max-width: 50px;
    width:50px;
    min-width: 50px;
}
.small-width{
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
    display: block !important;
}
.med-width{
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
    display: block !important;
}
.table-chckbox .p-checkbox {
    margin-left: 6px;
}
// user management table
// #user_table .p-datatable-scrollable-body{

//     min-height: calc(100% - 300px);
//     background: #fff;
// }

i95-tables{
    .p-inputswitch{
    
    height: 20px;    
    width: 38px;
    float: left;
    margin-top: 3px;
    padding-left: 0px !important;
    .p-inputswitch-slider:before {
        height: 15px;
        width: 15px;
        margin-top: -8px;
        }
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(15px);
    }
}


// removing panel for overlay panel
.remove-padding{
    .p-overlaypanel-content{
      padding: 0px !important;
    }
}


app-form-wizard{
    .p-inputswitch{
    
        height: 30px;
        float: left;
        margin-top: 3px;
        padding-left: 0px !important;
    
    }
}



.module-container{
    background: #fff;
    width: calc(100% - 20px);
    border-radius: 10px;
    margin: auto 10px;
    height: calc(100% - 10px);
    padding: 10px;
    z-index: 98;
    position: absolute;
    // box-shadow: 0px 1px 8px grey;

}

                   .p-card .p-card-content{
    padding-top: 1px;
}

.noresize{
    resize: none;
}

.asterisk_required{
    color: var(--bs-danger);
}



// error form
.has-error{
    color: var(--bs-danger);
    border-color: var(--bs-danger);
}
.has-error .p-multiselect, .has-error .p-dropdown, .has-error input, .has-error textarea, .has-error .selected-container{
    color: var(--bs-danger);
    border-color: var(--bs-danger) !important;
}


.p-datatable{
    .p-inputtext, .p-dropdown, .p-multiselect {
        font-size: 12px;
        padding: 3px 5px;
    }
}
.file_viewer .p-dialog-content{
    padding: 0px !Important;
    background: none !important;
    color: #fff;
    overflow: hidden;
}
.file_viewer .p-dialog-content .img-viewer{
    width: 100%;
    object-fit: scale-down;
    max-height: 40vw;
    height: auto;
}
.p-datepicker table td {
    padding: 2px;
}

.p-dialog-content{
    padding-bottom: 15px !Important;
}

.project-selection{
    max-width: 300px;
    min-width: 250px;
    width: auto;
    float: left;
}
.i95-grp-btn{
    width: auto;
    float: right;
    margin: 0px 15px;
    background: #f3f0ff;
    border-radius: 25px;    
    padding: 0px;
    white-space: nowrap;
    &.float-left{
        float: left;
    }
    .btn{
        color: $color-theme-dialog;
        margin: 0px 0px;
        border-radius: 20px;
        padding: 8px 20px;
    }
    .btn.active{
        color: #fff;
        background-color: $color-theme-dialog;
    }
}
.i95-grp-tab{
    // padding-top: 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    .tab{
        float: left;
        cursor: pointer;
        padding: 10px 10px;
        margin-right: 10px;
    }
    .tab.active{
            border-bottom: solid 2px #000;
    }


}

.p-datatable-table{
    width: 100% !important;
}




.i95-left-col .i95-grp-btn .btn{
    font-size:12px;
    padding: 8px 13px;
}


.upper-portion{
     padding: 10px 0px 15px;
     float: left;
     width: 100%;
}


// styling chat dialog
.i-message-container{
    max-height: 100% !important;
    .p-dialog-header{
        background-color: rgb(61, 67, 102);
        color: #fff;
        .pi{
            color: #fff;
        }
    }



}



//dialog custom style
.i95-dialog{
    .p-dialog-header{
        // background-color: $color-theme-dialog !important;
        // color: #fff !important;
    }
    .p-dialog-content{
        padding: 0px !important;
    }

    .i95-form-col{
        width: calc(100% - #{$width-dialog-left});
        float: left;    
        padding: 0px;
        padding-right: 40px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    
    }
    .p-dialog-footer{
        width: calc(100% - #{$width-dialog-left});
    }

    .p-confirm-dialog .p-dialog-header{
        background-color: #fff !important;
        color: #000 !important;
    }
    .p-confirm-dialog .p-dialog-content {
        padding: 1.5rem 1.5rem 2rem 1.5rem !important;
    }
    .p-confirm-dialog .p-dialog-footer {
        width: 100%;
    }
    
    .i95-card-dialog{
        float: left;
        width: 100%;
        height: calc(100% - 80px);
        overflow: auto;
    }
} 





.i95-left-col{
    float: left;
    padding: 0px;
    width: $width-dialog-left;
    padding-right: 20px;
    position: absolute;
    right: 0px;
    height: calc(100% - 90px);

    .i95-right-content-container{
        position: relative;
        top: 0px;
        height: 100%;
        width: 100%;
        float: left;
    }

}
.i95-left-bg{
    background-color: #e9eff3;
    height: 100%;
    width: calc(20px + #{$width-dialog-left});
    position: absolute;
    top: 0px;
    right: 0px;
}
.i95-left-bg .close{
    position: relative;
    right: 5px;
    float: right;
    top: 5px;
    font-size: 14px;
    padding: 3px 10px;
}



.link{
    color: #2196f3;
    font-weight: 600;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    width: auto;
    float: left;
}




// pagination css
.p-paginator .p-paginator-current {
    font-size: 13px;
}
.p-datatable .p-paginator-bottom {
    height: 50px;
    padding: 10px 0px;
}
.p-paginator .p-paginator-current {
    height: 30px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight, .p-paginator .p-paginator-pages .p-paginator-page, .p-paginator button  {
    font-size: 13px;
    height: 30px !important;
    min-width: 30px !important;
}
.p-paginator .p-dropdown .p-dropdown-trigger {
    width: 25px;
    height: 25px;
}
.p-paginator .pi{
    font-size: 13px;
}

.p-paginator .p-dropdown{
    height: 30px;
}






.i95-creator-modal-container{
    float: left;
    width: auto !important;
    font-size: 11px;
    color: #3a6276;
    text-align: left;
    line-height: 14px;
}
.i95-creator-modal-container td{
    padding-right: 10px;
}


// chat user search
$chat-user_font: #fff;
.chat-search-user{
    border: none;
    border-radius: 0;
    padding: 10px;
    box-shadow: none !important;
    color: $chat-user_font;
    float: left;
    width: calc(100% - 80px) !important;

    .p-multiselect-label{
        color: $chat-user_font;
        height: 31px;
        margin: 3px 0px;
    }
    .p-multiselect:hover {
        border: none;
    }
    .p-checkbox{
        display: none;
    }
}


.system-nav-btn-container::-webkit-scrollbar {
    height: 0px !important;
}




$dark-color-font: #303030;
$dark-color: #303030;
$darker-color: #121212;


.dark_mode_on{

    .p-dropdown-items {
        background: $dark-color !important;
    }

    .p-tabmenu .p-tabmenu-nav,.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        background: #303030;
    }
    .p-divider.p-divider-horizontal .p-divider-content {
        color: white;
        background: #303030;
    }
    .file-item:hover {
        background: #121212 !important;
    }

    .p-datatable .p-datatable-header {
        background: #000000 !important;
        color: #f8f9fa;
    }
    .module-container {
        background: #000000;
        color: #f8f9fa;
    }
    
    .upload-box{
        background: #171717 !important;
    }

    .p-inputtext, .p-dropdown, .p-multiselect {
       color: #fff;
       background-color: #292929;
    }

    .p-checkbox .p-checkbox-box {
       color: #fff;
       background-color: #292929;
       border-color: #292929;
    }

    .i95-header, .main_content{
        background: #000000cf;
    }
    .p-card{
        background: #000;
    }
    .p-datatable .p-datatable-thead > tr > th, .p-dialog .p-dialog-header {
        background: #191919;
        color: #fff;
        border: 1px solid #393939
    }

    .p-dialog .p-dialog-content {
  
        background: #303030;
        color: #fff;
    
    }

    .p-datatable .p-datatable-tbody > tr > td {
        background: #202020;
        color: #fff;
        border: 1px solid #393939;
    }
    .p-datatable .p-paginator-bottom {
        background: #191919;
        border: solid 1px#393939;
        color: rgb(65, 39, 39);
    }
    .p-datatable .p-datatable-tfoot {
        background: #191919;
        color: #fff;
        font-size: 13px;
        border: solid 1px#393939;
    }

    .p-datatable-scrollable-header-box, .p-datatable-scrollable-footer{
        background: #303030;    
    }
    
    .p-dialog-header, .p-dialog-footer {
        background: #191919;   
    }

        /* width */
        ::-webkit-scrollbar {
            background: #303030;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            border: solid 1px #303030;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(31, 31, 31);;
            border-radius: 4px;
            border: solid 1px #f2f2f2;
            box-shadow: inset 0 0 5px rgb(19, 19, 19);
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: rgb(31, 31, 31);; 
        }

}

// normal view
.i95-table{

    .p-editable-column{
        input, textarea, .p-dropdown{ 
            background-color: #fff;
            border-radius: 0px;
            width: 100% !important;
            resize: none;
        }
    }
    p-celleditor, p-columnfilter{
        width: 100% !important;
    }
    .p-paginator-bottom {
        position: absolute;
        bottom: -10px;
        width: 100%;
    }
    .p-fc-right{
        border-left: solid 1px #ececf0 !important;
    }
    .p-fc-right .p-frozen-col-inner{
        margin: auto;
    }
    .p-column-filter-clear-button{
        display: none;
    }
    tr, td, th {
        flex: 1 0 auto !important;
    }
    .p-datatable-tfoot small{
        position: absolute;
        bottom: 0px;
        width: 100%;
        text-align: right;
        right: 10px;
    }
    .p-column-value{
        float: left;
        width: 100%;
        word-break: break-all;
    }
    .p-colorpicker{
        width: 100% !important;
        text-align: center;
        .p-colorpicker-preview{
          width: 25px !important;
          height: 25px !important;
          border-radius: 5px !important;
          margin: auto;
        }
      }
}


//hide when in window model
.is_mobile_show{
    display: none;
}




// mobile view
.is_mobile{

    .div_center{
        left: 0 !important;
        float:left;
      }
      .div_center_inner{
        left: 0 !important;
        float:left;
      }

    // login page
    .login-body{
        .img-cp-1{
            display:none !important;
         } 
         .img-cp-2{
             display:none !important;
         }
    }

// i95 modal
.i95-modal{ 
    
    max-height: 100%;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
}



    // landing page
    .home-carousel-bg{
        margin-top: 10px !important;
        width:100% !important;
        float: left !important;
        height: 300px  !important;
        
        .carousel-nav{
            // float: left;
            // position: absolute;
            // width: 100%;
            // div{
            //     width: 120px;
            //     margin:auto;
            // }
        }
        .home-brand{
            top: 0px;
            left: 0px;
            height: auto;
            position: absolute;
            top: 300px;
            width: 80%;
        }
    }
    .office-timezone-container{
        display:none;
        // .office-tz{
        //     min-width: 120px !important;
        // }
        // .office-timezone{
        //     height: auto;
        //     border-radius: 10px;
        //     float: left;
        // }
    }





    // project option
    .project-selection{
        max-width: 100%;
        width: 100%;
    }

    // hidden when mobile mode
    .is_not_mobile{
        display: none;
    }
    .is_mobile_show{
        display: block;
    }

    .i95-header-function-btn {
        margin-right: 5px !important;
    }


    .app-box-container{
        width: 100px;
    }
    .module-container{
        z-index: 1000;
    }

    // i95 dialog
    .i95-dialog {
        
        width: 100% !important;
        // max-width: 500px;
        height: 100% !important;
        max-height: 100%;

        .i95-left-bg{
            width: 100%;
            height: calc(100% - 53px);
            top: 53px;
        }

        .i95-left-bg .btn-danger.close{
            display: none;
        }
        .p-dialog-footer {
            position: absolute;
            bottom: 0px;
            width: 100%;
        }
        .i95-left-col {
            position: absolute;
            width: 100%;
            padding: 0px 15px;
        }
        .msg-comment-container {
            height: calc(100vh - 290px);
        }

        .file-container{
            max-height: calc(100vh - 250px);
        }


    }
    // mobile view
    .i95-table{

        .p-column-title{
            float: left;
            width: 100px !important;
            min-width: 100px !important;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .p-column-value{
            float: left;
            margin-left: 10px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100vw - 182px);
            min-width: calc(100vw - 182px);
        }
        tr{
            position: relative;
            border-top: solid 1px #dde2e6;
        }

        .p-frozen-col-inner{
                position: absolute;
                top: 10px;
                box-shadow: none !important;
                border: none;
                border-left: 0px !important;
                right: 10px;
        }

        .p-fc-right{
                box-shadow: none !important;
                border-left: 0px !important;
                border: none;
        }
        .p-datatable-tbody td {
           padding: 0px !important;
        }
        .p-paginator-bottom{
            .p-paginator-current, .p-paginator-element, .p-paginator-rpp-options{
                display: none;
            }

        }
        .is_not_mobile_col{
            display: none !important;
        }
        
    }

    .login-container{
        width: 89%;
        z-index: 9;
        max-width: calc(100% - 20px) !important;
        min-width: calc(100% - 20px) !important;
        margin: 24% 10px 0px 10px !important;
        z-index: 9 !important;
        position: fixed;
    }
    .login-header{
        margin-top: 0px;
    }
    .app-box-container{
        width: 130px !important;
    }



    .system_card .p-card-body{

        padding-left: 0px;
        padding-right: 0px;
    }
    .function_btn{
        position: fixed;
        bottom: 10px;
        left: 0px;
        width: calc(100% - 20px);
        height: 45px;
        margin: 0px 10px;

        .p-button{
            background-color: #fff;
            color: #097ad1;
            border: none;
            box-shadow: none;
            padding: 0px !important;
            width: 33%;
            float: left;
            text-align: center;
            margin: 0px !important;
            height: 40px;
            padding: 13px 0px;
            .p-button-label{
                display: none;
            }
            .p-button-icon{
                text-align: center;
                width: 100%;
                padding: 13px 0px !important;
            }
            
        }
        .p-button:enabled:hover {
            background: rgba(252, 252, 252, 0);
            color: #000000;
            border: none;
            box-shadow: none;
        }

    }


    // system nav container with
    .system-nav-btn-container{
        overflow-y: auto;
        white-space: nowrap;
        height: 38px;
        padding-bottom: 0px !important;

        a{
            height: 30px;
        }
        span{
            font-size: 12px;
        }

        ::-webkit-scrollbar {
            height: 0px !important;
        }
        .active, .p-highlight{
            background-color: transparent !important;
            border: none;
            color: #607d8b !important;
            font-weight: bold !important;
            border-bottom: solid 2px;
            border-radius: 0px !important;
        }
        .btn:active{
            display: none;
        }
        .p-button{
            padding: 0px !important;
            padding-right: 10px !important;
            margin-left: 0px !important;
            margin-right: 15px;
        }
    }

    #usermodal{ 
        .p-dialog-header{
            background: #607d8b;
            color: #fff;
            .pi{
                color: #fff;
            }
        }
        .p-dialog{
            height:100%;
            width:100% !important;
            max-width:100%;
            max-height: 100%;
        }
    }

    .i95-modal{ 
        .p-dialog-header{
            background: #607d8b;
            color: #fff;
            .pi{
                color: #fff;
            }
        }
        .p-dialog{
            height:100vh;
            width:100% !important;
            max-width:100%;
        }
    }

    // chat container
    .i-message-container{
        max-width: 100%;
        min-width: 100%;
        min-height: 100%;
    }    
    .chat-main-container{
        .chat-user-container{
            width: 100%;
        }
        .chat-top-container{
            .chat-title{
                float: none !important;
                position: relative;
                top: 7px;
            }
        }
        .chat-head{
            padding-left: 50px !important;
        }
        .mobile-bck-btn{
            position: absolute;
            top: 7px;
            left: 7px;
            color: #fff;
        }

        .chat-msg-container{
            position: absolute;
            width: 100%;
        }


    }



}


.layout-list{
    white-space: nowrap;
    font-size: 11px;
}


.general_filter{
    width: 100%;
    height: 55px;
    background: #f6f6f6;
    border-radius: 10px;
    padding: 10px;
    margin-top: 8px;
    .p-inputtext, .p-dropdown{
        border-radius: 10px !important;
        width: 100%;
        background: #fff;
    }
    .filter-col{
        margin-right: 10px;
        float: left;
        width: 150px;    
        position: relative;
        .filter-remove-btn{
            position: absolute;
            top: 11px;
            right: 12px;
            color: #868686;
            cursor: pointer;
        }
        .p-inputwrapper-filled .p-dropdown-trigger-icon{
            display: none;
        }
        .p-dropdown-trigger-icon{
            position: absolute;
            right: 11px;
        }
        .p-inputwrapper-filled .p-dropdown-clear-icon {
            right: 11px;
        }
    }

    .filter-btns{
        margin-left: 10px;
        float: left;
        width: auto;
    }
    
    .filter-search{
        width: 200px;
    }
}


// custom stepper


.stepper-wrapper {
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .stepper-container{
        min-width: 100px;
        text-align: center;
        width: 100%;
        position: relative;
    }
    .stepper-progress {
        width: 100%;
        position: relative;
    }
    .stepper-progress::before {
        content: "";
        top: 16px;
        width: 100%;
        height: 2px;
        background-color: lightgray;
        position: absolute;
        z-index: -2;
        left: -50%;
    }
    .stepper-progress:last-of-type::before {
        width: 0px;
    }
    .stepper-progress::after {
        content: "";
        width: 0%;
        height: 2px;
        background-color: #1e64e7;
        position: absolute;
        left: -50%;
        z-index: -1;
        top: 16px;
        transition: width 1s;
      }
      .stepper-screen-indicator {
        cursor: pointer;
        margin: auto;
        margin-bottom: 3px;
        border-radius: 50%;
        border: 2px solid lightgrey;
        background-color: white;
        padding: 6px 5px;
        color: gray;
        transition: border-color color;
        transition-duration: 0.7s;
        width: 33px;
        height: 33px;
      }
      .stepper-completed {
        border-color: rgb(78, 196, 243);
        color: rgb(78, 196, 243);
      }

      .stepper-completed {
        color: #1e64e7;
        .stepper-screen-indicator{
            background-color: #1e64e7;
            color: #fff;
            border-color: #1e64e7;
        }
        .stepper-label{
            font-weight: 500;
        }
      }
      .stepper-active {
        color: #1db43d;
        .stepper-screen-indicator{
            background-color: #fff;
            color: #1db43d;
            font-weight: 600;
            border-color: #1db43d;
        }
        .stepper-label{
            font-weight: 600;
        }
      }

      .stepper-completed .stepper-progress::after {
        content: "";
        width: 100%
      }
  }

  .fill-cell{
    // height: 100%;
    width: 100%;
    // display: block;
    // overflow: auto;
  }


  //small size
  .i95-table-sm{
    pre, span, th, td{
        font-size: 11px !important;
    }
    .p-inputswitch {
        width: 34px;
        height: 16px;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        height: 12px;
        width: 12px;
        margin-top: -6px;
    }
    .btn-sm{
        font-size: 11px;
    }
    .btn-round{
        border-radius:5px;
        padding: 3px 8px;
    }
    .switch input:checked + .slider:before {
        left: -8px !important;
    }

}
.bg-white{
    background-color:#fff;
}
.divider{
    float: left;
    width: 100%;
    border-top: solid 1px #E7E3E3;
    height: 1px;
    margin: 5px 0px;

}

.w-33{
    width: 33.33% !important;
}
.w-66{
    width: 66.33% !important;
}

.r-100{
    right:100px !important;
}
.r-80{
    right:80px !important;
}
.uppercase{
    input{
        text-transform: uppercase;
    }

}
.float-left{
    float:left !important;
}
.float-right{
    float:right !important;
}
.multihead-checkbox-remove{
    .p-multiselect-panel{
        .p-multiselect-header {
            .p-checkbox{
                display: none !important;
            }
        
        }
    }
}
.roybot-sidebar{
    .more-filter-container{

        z-index: 150 !important;
        position: fixed;
        right: 0px;
        width: 100vw;
        top: 0;
        height: 100vh;
        .more-filter-backdrop{
          background-color: rgb(0 0 0 / 34%);
          z-index: 151 !important;
          position: fixed;
          right: 0px;
          width: 100vw;
          top: 0;
          height: 100vh;
        }
        .more-filter-inner{
          background-color: #fff;
          z-index: 152 !important;
          position: fixed;
          right: 0px;
          width: 450px;
          top: 0;
          height: 100vh;
      
          .field{
            margin-bottom: 0px !important;
          }
          .more-filter-bottom{
            position: absolute;
            bottom: 2px;
          }
        }
      }
    
}